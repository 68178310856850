var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-data-table",
        _vm._b(
          {
            attrs: { headers: _vm.itemHeadings, items: _vm.itemsList },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.itemNumber",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.viewFunction(item)
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-folder-open"),
                          ]),
                          _vm._v(" " + _vm._s(item["itemNumber"]) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                },
                _vm.title
                  ? {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-toolbar",
                            { attrs: { flat: "" } },
                            [
                              _c("v-toolbar-title", [
                                _vm._v(_vm._s(_vm.title)),
                              ]),
                              _c("v-divider", {
                                staticClass: "mx-4",
                                attrs: { inset: "", vertical: "" },
                              }),
                              _c("v-spacer"),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                {
                  key: "item.actions",
                  fn: function (/* eslint-disable vue/no-unused-vars */ ref) {
                    var item = ref.item
                    return [
                      _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-pencil",
                          "button-tooltip": "Edit",
                          icon: "",
                        },
                        on: {
                          confirmed: function ($event) {
                            return _vm.editFunction(item)
                          },
                        },
                      }),
                      !_vm.noDeleteInTable
                        ? _c("asyent-button", {
                            attrs: {
                              "button-icon": "mdi-delete",
                              "button-tooltip": "Delete",
                              icon: "",
                              "requires-confirmation": "",
                            },
                            on: {
                              confirmed: function ($event) {
                                return _vm.deleteFunction(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          "v-data-table",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }