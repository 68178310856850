var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("asyent-button", {
        attrs: {
          "button-icon": "mdi-database-import",
          "button-tooltip": _vm.dialogTitle,
          icon: _vm.$vuetify.breakpoint.smAndDown,
          text: !_vm.$vuetify.breakpoint.smAndDown,
        },
        on: {
          confirmed: function ($event) {
            _vm.isVisible = true
          },
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            scrollable: "",
            transition: "dialog-bottom-transition",
            width: "75vw",
          },
          model: {
            value: _vm.isVisible,
            callback: function ($$v) {
              _vm.isVisible = $$v
            },
            expression: "isVisible",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pa-0 ma-0",
              staticStyle: { "border-radius": "0" },
              attrs: { color: "cardDefault", flat: "" },
            },
            [
              _c(
                "v-toolbar",
                {
                  staticClass: "mb-4",
                  attrs: { color: "primary", dark: "", "max-height": "56" },
                },
                [
                  _c("v-toolbar-title", {
                    domProps: { textContent: _vm._s(_vm.dialogTitle) },
                  }),
                  _c("v-spacer"),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-check",
                      "button-tooltip": _vm.ae$prefixLng(
                        "catalog",
                        "dialog.button.save"
                      ),
                      dark: "",
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.save },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": _vm.ae$prefixLng(
                        "catalog",
                        "dialog.button.cancel"
                      ),
                      dark: "",
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.close },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  style: {
                    "max-height": _vm.$vuetify.breakpoint.smAndUp ? "75vh" : "",
                  },
                },
                [
                  _vm.errors.length > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { staticClass: "pb-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: { type: "error", transition: "fade" },
                                },
                                _vm._l(_vm.errors, function (message, i) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: i,
                                      staticClass: "text-body-2",
                                      attrs: { dense: "" },
                                    },
                                    [_vm._v(" " + _vm._s(message) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            { attrs: { color: "cardDefault", flat: "" } },
                            [
                              _c("div", { staticClass: "container" }, [
                                _c("div", { staticClass: "panel panel-sm" }, [
                                  _c(
                                    "div",
                                    { staticClass: "container" },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.parsed,
                                              expression: "!parsed",
                                            },
                                          ],
                                          class: {
                                            "grey lighten-2": _vm.dragover,
                                          },
                                          on: {
                                            drop: function ($event) {
                                              $event.preventDefault()
                                              return _vm.onDrop($event)
                                            },
                                            dragover: function ($event) {
                                              $event.preventDefault()
                                              _vm.dragover = true
                                            },
                                            dragenter: function ($event) {
                                              $event.preventDefault()
                                              _vm.dragover = true
                                            },
                                            dragleave: function ($event) {
                                              $event.preventDefault()
                                              _vm.dragover = false
                                            },
                                            click: function ($event) {
                                              return _vm.$refs.filebtn.$refs.input.click()
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "d-flex flex-column",
                                                  attrs: {
                                                    dense: "",
                                                    align: "center",
                                                    justify: "center",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      class: [
                                                        _vm.dragover
                                                          ? "mt-2, mb-6"
                                                          : "mt-5",
                                                      ],
                                                      attrs: { size: "60" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-cloud-upload "
                                                      ),
                                                    ]
                                                  ),
                                                  _c("p", [
                                                    _vm._v(
                                                      " Drop your file here, or click to select it. "
                                                    ),
                                                  ]),
                                                  _c("v-file-input", {
                                                    ref: "filebtn",
                                                    staticStyle: {
                                                      display: "none",
                                                    },
                                                    attrs: {
                                                      clearable: "",
                                                      "show-size": "",
                                                      label: "File input",
                                                      accept: ".csv",
                                                      multiple: _vm.multiple,
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleFileUpload,
                                                    },
                                                    model: {
                                                      value: _vm.file,
                                                      callback: function ($$v) {
                                                        _vm.file = $$v
                                                      },
                                                      expression: "file",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm.uploadedFiles.length > 0
                                                ? _c("v-virtual-scroll", {
                                                    attrs: {
                                                      items: _vm.uploadedFiles,
                                                      height: "150",
                                                      "item-height": "50",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function (ref) {
                                                            var item = ref.item
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  key: item.name,
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.name
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticClass:
                                                                                "ml-3 text--secondary",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.size
                                                                                  ) +
                                                                                  " bytes "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              icon: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.removeFile(
                                                                                  item.name
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                " mdi-close-circle "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c("v-divider"),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4222449328
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.parsed,
                                              expression: "parsed",
                                            },
                                          ],
                                        },
                                        [
                                          _c("h2", [
                                            _vm._v(
                                              _vm._s(_vm.ae$lng("Preview Data"))
                                            ),
                                          ]),
                                          _c("hr"),
                                          _c("v-data-table", {
                                            staticClass: "elevation-1",
                                            attrs: {
                                              headers: _vm.header,
                                              items: _vm.tableData,
                                              "items-per-page": 10,
                                              "show-select": "",
                                              "no-data-text":
                                                _vm.ae$lng("No data found"),
                                              "hide-default-footer": "",
                                              "item-key": _vm.key1,
                                            },
                                            model: {
                                              value: _vm.selectedItems,
                                              callback: function ($$v) {
                                                _vm.selectedItems = $$v
                                              },
                                              expression: "selectedItems",
                                            },
                                          }),
                                          _c("br"),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }