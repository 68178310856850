var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    _vm._b(
      {
        attrs: {
          "form-action-method": _vm.formActionWindow,
          "form-actions": _vm.filteredFormActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          "page-path": _vm.pagePath,
          title: _vm.title,
          "title-icon": "mdi-file-table",
          "initial-width": "4",
          "toolbar-actions": _vm.toolbarActions,
        },
        on: {
          "toolbar-action": function ($event) {
            return _vm.toolbarMethod($event)
          },
        },
      },
      "asyent-window",
      _vm.$attrs,
      false
    ),
    [
      _vm.hasHistory
        ? _c("AsyhubHistory", {
            ref: "postalHistory",
            attrs: {
              id: _vm.id,
              "items-reload": _vm.historyReload,
              "component-name": "PostalConsignmentsForm",
              name: "consignment-history",
              "service-name": "cargo",
            },
            model: {
              value: _vm.showHistory,
              callback: function ($$v) {
                _vm.showHistory = $$v
              },
              expression: "showHistory",
            },
          })
        : _vm._e(),
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "view-mode": _vm.viewMode,
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.filteredFormActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _vm.rop !== "new"
                          ? _c("asyent-form-info", {
                              attrs: { "no-title": "", "no-maximize": "" },
                              model: {
                                value: _vm.formData,
                                callback: function ($$v) {
                                  _vm.formData = $$v
                                },
                                expression: "formData",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["providerName"],
                                        label: "Provider",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          _vm.formDataF["providerObjectId"],
                                        label: "Provider Consignment ID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          _vm.formDataF["totalValueAmount"],
                                          _vm.formDataF["consignmentCurrency"],
                                        ],
                                        label: "Total Value",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: [
                                          _vm.formDataF[
                                            "manifestedGrossWeight"
                                          ]["value"],
                                          _vm.formDataF[
                                            "manifestedGrossWeight"
                                          ]["unit"],
                                        ],
                                        label: "Manifested Gross Weight",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["arrivalStatus"],
                                        label: "Arrival Status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.formDataF["itemsNumber"],
                                        label: "Number of Items",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value: _vm.customsActionDisplay(
                                          _vm.formDataF["customsAction"]
                                        ),
                                        label:
                                          "Customs\n                Action",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4", lg: "3" } },
                                  [
                                    _c("asyent-form-display", {
                                      attrs: {
                                        value:
                                          _vm.formDataF["postalConsignment"][
                                            "receptacleId"
                                          ],
                                        label: "Receptacle ID",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-col",
                                      [
                                        _c(
                                          "asyent-card",
                                          {
                                            attrs: {
                                              title: "Category",
                                              flat: "",
                                              "border-color": 2,
                                              "with-border": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _vm.getEnv === "kh"
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "postalConsignmentSystemCategory"
                                                                        ],
                                                                      label:
                                                                        "Postal Consignment System Category",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.getEnv === "kh"
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-catalog",
                                                                  {
                                                                    attrs: {
                                                                      sortable:
                                                                        "",
                                                                      "view-mode":
                                                                        _vm.viewMode,
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      "service-name":
                                                                        "catalog",
                                                                      catalog:
                                                                        "CatalogPostalConsignmentCategory",
                                                                      "show-item-code":
                                                                        "",
                                                                      label:
                                                                        "New Postal Consignment Category",
                                                                      "item-value":
                                                                        "code",
                                                                      "item-text":
                                                                        "description",
                                                                      name: "consignmentType",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "postalConsignmentOfficerCategory"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.formDataF,
                                                                            "postalConsignmentOfficerCategory",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "formDataF['postalConsignmentOfficerCategory']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.getEnv === "kh"
                                                          ? _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "12",
                                                                  md: "4",
                                                                  lg: "3",
                                                                },
                                                              },
                                                              [
                                                                _vm.viewMode
                                                                  ? _c(
                                                                      "asyent-form-display",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "postalConsignmentCategoryDecision"
                                                                            ],
                                                                          label:
                                                                            "Postal Consignment Category Decision",
                                                                        },
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "asyent-form-text",
                                                                      {
                                                                        attrs: {
                                                                          "view-mode":
                                                                            _vm.viewMode,
                                                                          "form-errors":
                                                                            _vm.formErrors,
                                                                          "form-rules":
                                                                            _vm.formFieldsRules,
                                                                          "is-disabled-func":
                                                                            _vm.isDisabled,
                                                                          label:
                                                                            "Postal Consignment Category Decision",
                                                                          name: "Postal Consignment Category Decision",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .formDataF[
                                                                              "postalConsignmentCategoryDecision"
                                                                            ],
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.formDataF,
                                                                                "postalConsignmentCategoryDecision",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "formDataF['postalConsignmentCategoryDecision']",
                                                                        },
                                                                      }
                                                                    ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: _vm.widthWithSad("postal"),
                                          md: _vm.widthWithSad("postal"),
                                        },
                                      },
                                      [
                                        _c(
                                          "asyent-card",
                                          {
                                            attrs: {
                                              title: "Postal",
                                              flat: "",
                                              "border-color": 2,
                                              "with-border": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "12" } },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "carrierBookingNumber"
                                                                    ],
                                                                  label:
                                                                    "Tracking Number",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "originatorId"
                                                                    ],
                                                                    _vm
                                                                      .formDataF[
                                                                      "originatorName"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Originator",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "idCargoVoyage"
                                                                    ][
                                                                      "departureDate"
                                                                    ],
                                                                  "as-date": "",
                                                                  label:
                                                                    "Posting Date",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "success",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .formDataF[
                                                                      "type"
                                                                    ]
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "loadingPlace"
                                                                    ]["code"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "loadingPlace"
                                                                    ]["name"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "loadingPlace"
                                                                    ][
                                                                      "countryName"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Loading Place",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "dischargePlace"
                                                                    ]["code"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "dischargePlace"
                                                                    ]["name"],
                                                                    _vm
                                                                      .formDataF[
                                                                      "dischargePlace"
                                                                    ][
                                                                      "countryName"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Discharge Place",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "flow"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "flow"
                                                                    ),
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "4",
                                                              lg: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .formDataF[
                                                                      "nature"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Nature",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.formDataF["awDeclaration"][
                                  "lastOperationTimestamp"
                                ] !== undefined
                                  ? _c(
                                      "v-col",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: _vm.widthWithSad("customs"),
                                              md: _vm.widthWithSad("customs"),
                                            },
                                          },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  title: "Customs",
                                                  flat: "",
                                                  "border-color": 2,
                                                  "with-border": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "12" } },
                                                      [
                                                        _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "awDeclarationReference"
                                                                        ][
                                                                          "customsOfficeCode"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "awDeclarationReference"
                                                                        ][
                                                                          "customsOfficeName"
                                                                        ],
                                                                      ],
                                                                      label:
                                                                        "Office Code",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value: [
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "awDeclarationReference"
                                                                        ][
                                                                          "registrationDate"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "awDeclarationReference"
                                                                        ][
                                                                          "registrationSerial"
                                                                        ],
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "awDeclarationReference"
                                                                        ][
                                                                          "registrationNumber"
                                                                        ],
                                                                      ],
                                                                      label:
                                                                        "Registration Reference",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "selectivityColor"
                                                                        ],
                                                                      label:
                                                                        "Selectivity Color",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "lastOperation"
                                                                        ],
                                                                      label:
                                                                        "Last Operation",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "lastStatus"
                                                                        ],
                                                                      label:
                                                                        "Last Status",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "4",
                                                                  md: "4",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .formDataF[
                                                                          "awDeclaration"
                                                                        ][
                                                                          "lastOperationTimestamp"
                                                                        ],
                                                                      label:
                                                                        "Last Operation Timestamp",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c("v-row"),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              extended: "",
                              expandable: "",
                              title: "Parties",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: {
                                                  cols: "12",
                                                  md: "2",
                                                  lg: "2",
                                                },
                                              },
                                              [
                                                _c("v-checkbox", {
                                                  attrs: {
                                                    disabled: _vm.viewMode,
                                                    label: "isIndividual",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formDataF[
                                                        "postalConsignment"
                                                      ]["individual"],
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formDataF[
                                                          "postalConsignment"
                                                        ],
                                                        "individual",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formDataF['postalConsignment']['individual']",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Sender",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          lg: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ]["name"],
                                                              label: "Name",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ]["id"],
                                                              label:
                                                                "Sender ID",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          lg: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "consignor"
                                                                ]["address"],
                                                              label: "Address",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _vm.viewMode
                                                          ? _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "consignor"
                                                                    ]["email"],
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "email"
                                                                    ),
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              "asyent-form-text",
                                                              {
                                                                attrs: {
                                                                  "view-mode":
                                                                    _vm.viewMode,
                                                                  "form-errors":
                                                                    _vm.formErrors,
                                                                  "form-rules":
                                                                    _vm.formFieldsRules,
                                                                  "is-disabled-func":
                                                                    _vm.isDisabled,
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "email"
                                                                    ),
                                                                  name: "consignor.email",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "consignor"
                                                                    ]["email"],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formDataF[
                                                                          "consignor"
                                                                        ],
                                                                        "email",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formDataF['consignor']['email']",
                                                                },
                                                              }
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _vm.viewMode
                                                          ? _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "consignor"
                                                                    ]["phone"],
                                                                  label:
                                                                    "Telephone",
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              "asyent-form-text",
                                                              {
                                                                attrs: {
                                                                  "view-mode":
                                                                    _vm.viewMode,
                                                                  "form-errors":
                                                                    _vm.formErrors,
                                                                  "form-rules":
                                                                    _vm.formFieldsRules,
                                                                  "is-disabled-func":
                                                                    _vm.isDisabled,
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "phone"
                                                                    ),
                                                                  name: "consignor.phone",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "consignor"
                                                                    ]["phone"],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formDataF[
                                                                          "consignor"
                                                                        ],
                                                                        "phone",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formDataF['consignor']['phone']",
                                                                },
                                                              }
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "12", lg: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          flat: "",
                                          color: "bgWhite",
                                          title: "Recipient",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          lg: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ]["name"],
                                                              label: "Name",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ]["id"],
                                                              label:
                                                                "Recipient ID",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "3",
                                                          lg: "3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "asyent-form-display",
                                                          {
                                                            attrs: {
                                                              value:
                                                                _vm.formDataF[
                                                                  "notifyParty"
                                                                ]["address"],
                                                              label: "Address",
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _vm.viewMode
                                                          ? _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "notifyParty"
                                                                    ]["email"],
                                                                  label:
                                                                    "Email",
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              "asyent-form-text",
                                                              {
                                                                attrs: {
                                                                  "view-mode":
                                                                    _vm.viewMode,
                                                                  "form-errors":
                                                                    _vm.formErrors,
                                                                  "form-rules":
                                                                    _vm.formFieldsRules,
                                                                  "is-disabled-func":
                                                                    _vm.isDisabled,
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "email"
                                                                    ),
                                                                  name: "notifyParty.email",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "notifyParty"
                                                                    ]["email"],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formDataF[
                                                                          "notifyParty"
                                                                        ],
                                                                        "email",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formDataF['notifyParty']['email']",
                                                                },
                                                              }
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      {
                                                        attrs: {
                                                          cols: "12",
                                                          md: "2",
                                                          lg: "2",
                                                        },
                                                      },
                                                      [
                                                        _vm.viewMode
                                                          ? _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "notifyParty"
                                                                    ]["phone"],
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "phone"
                                                                    ),
                                                                },
                                                              }
                                                            )
                                                          : _c(
                                                              "asyent-form-text",
                                                              {
                                                                attrs: {
                                                                  "view-mode":
                                                                    _vm.viewMode,
                                                                  "form-errors":
                                                                    _vm.formErrors,
                                                                  "form-rules":
                                                                    _vm.formFieldsRules,
                                                                  "is-disabled-func":
                                                                    _vm.isDisabled,
                                                                  label:
                                                                    _vm.ae$lng(
                                                                      "phone"
                                                                    ),
                                                                  name: "notifyParty.phone",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formDataF[
                                                                      "notifyParty"
                                                                    ]["phone"],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formDataF[
                                                                          "notifyParty"
                                                                        ],
                                                                        "phone",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "formDataF['notifyParty']['phone']",
                                                                },
                                                              }
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c("items-consignment-items", {
                          attrs: {
                            "is-disabled-func": _vm.isDisabled,
                            "item-headings": _vm.itemTableHeaders,
                            "item-values": [
                              "itemNumber",
                              "hsCode",
                              "commodityCode",
                              ["transportPackages"]["packageNumber"],
                              "grossWeight",
                              "description",
                            ],
                            readonly: _vm.isReadonly,
                            "view-mode": _vm.viewMode,
                            name: "ConsignmentItemList",
                            title: _vm.ae$lng("ConsignmentItemList"),
                          },
                          model: {
                            value: _vm.formDataF["consignmentItems"],
                            callback: function ($$v) {
                              _vm.$set(_vm.formDataF, "consignmentItems", $$v)
                            },
                            expression: "formDataF['consignmentItems']",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              title: _vm.ae$lng("Scanneddocumentlist"),
                              expandable: "",
                            },
                          },
                          [
                            _c("MediaDocument", {
                              attrs: {
                                "view-mode": _vm.viewMode,
                                "media-scope": "cargo",
                              },
                              model: {
                                value: _vm.formDataF["medias"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.formDataF, "medias", $$v)
                                },
                                expression: "formDataF['medias']",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              collapsed: "",
                              expandable: "",
                              title: "Risk Assessment Result",
                            },
                          },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Loading Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "plpSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.showRisk(
                                                        "plpSystemRiskLevel"
                                                      ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "plpOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "plpOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Pre-Arrival Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "papSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value:
                                                      _vm.showRisk(
                                                        "papSystemRiskLevel"
                                                      ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "papOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "papOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", md: "4" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          title: "Post-Arrival Selectivity",
                                          flat: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "postSystemRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postSystemRiskLevel"
                                                    ),
                                                    label: "System Risk",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "6" } },
                                              [
                                                _c("asyent-form-display", {
                                                  staticClass: "pa-2",
                                                  style: {
                                                    "background-color":
                                                      _vm.showRiskAsColor(
                                                        "postOfficerRiskLevel"
                                                      ),
                                                  },
                                                  attrs: {
                                                    value: _vm.showRisk(
                                                      "postOfficerRiskLevel"
                                                    ),
                                                    label: "Officer Decision",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "asyent-card",
                                      {
                                        attrs: {
                                          extended: "",
                                          expandable: "",
                                          title: "Selectivity Results",
                                        },
                                      },
                                      [
                                        _c("v-data-table", {
                                          staticClass: "pa-0 ma-0",
                                          attrs: {
                                            headers:
                                              _vm.selectivityTableHeaders,
                                            items:
                                              _vm.formDataF[
                                                "selectivityResults"
                                              ],
                                            "items-per-page": -1,
                                            "hide-default-footer": "",
                                            "multi-sort": "",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "item.riskAnalysisCategory",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: { text: "" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.selectivityDialog = true
                                                          _vm.selectivityItem =
                                                            item
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { attrs: { left: "" } },
                                                        [
                                                          _vm._v(
                                                            "mdi-folder-open"
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item[
                                                              "riskAnalysisCategory"
                                                            ]
                                                          ) +
                                                          " "
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                        }),
                                        _c(
                                          "v-dialog",
                                          {
                                            attrs: {
                                              fullscreen:
                                                _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              persistent: "",
                                              scrollable: "",
                                              transition:
                                                "dialog-bottom-transition",
                                            },
                                            model: {
                                              value: _vm.selectivityDialog,
                                              callback: function ($$v) {
                                                _vm.selectivityDialog = $$v
                                              },
                                              expression: "selectivityDialog",
                                            },
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { color: "bgWhite" } },
                                              [
                                                _c(
                                                  "v-toolbar",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                      "max-height": "56",
                                                    },
                                                  },
                                                  [
                                                    _c("v-toolbar-title", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectivityItem[
                                                            "riskAnalysisCategory"
                                                          ]
                                                        ) +
                                                          " Risk Analysis on " +
                                                          _vm._s(
                                                            _vm.selectivityItem[
                                                              "eventTypeName"
                                                            ]
                                                          )
                                                      ),
                                                    ]),
                                                    _c("v-spacer"),
                                                    _c("asyent-button", {
                                                      attrs: {
                                                        "button-icon":
                                                          "mdi-close",
                                                        "button-tooltip":
                                                          "Cancel",
                                                        dark: "",
                                                        icon: "",
                                                      },
                                                      on: {
                                                        confirmed: function (
                                                          $event
                                                        ) {
                                                          _vm.selectivityDialog = false
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "triggerTime"
                                                                    ],
                                                                  label:
                                                                    "Trigger Time",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "systemRiskLevel"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "System Risk Level",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value: [
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "riskAnalysisResult"
                                                                    ],
                                                                  ],
                                                                  label:
                                                                    "Risk Analysis Result",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "3",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-form-display",
                                                              {
                                                                attrs: {
                                                                  value:
                                                                    _vm
                                                                      .selectivityItem[
                                                                      "officerRiskLevel"
                                                                    ],
                                                                  label:
                                                                    "Officer Risk Assessment",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Hit Criteria",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-data-table",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0 ma-0",
                                                                    attrs: {
                                                                      headers:
                                                                        _vm.criteriaTableHeaders,
                                                                      items:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "hitCriteria"
                                                                        ],
                                                                      "items-per-page":
                                                                        -1,
                                                                      "hide-default-footer":
                                                                        "",
                                                                      "multi-sort":
                                                                        "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Officer Risk Description",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-display",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "officerRiskDescription"
                                                                        ],
                                                                      label: "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "12",
                                                              md: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asyent-card",
                                                              {
                                                                attrs: {
                                                                  title:
                                                                    "Selected Controls",
                                                                  flat: "",
                                                                  "border-color": 2,
                                                                  "with-border":
                                                                    "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "asyent-form-select",
                                                                  {
                                                                    attrs: {
                                                                      "form-errors":
                                                                        _vm.formErrors,
                                                                      "form-rules":
                                                                        _vm.formFieldsRules,
                                                                      "is-disabled-func":
                                                                        _vm.isDisabled,
                                                                      "items-reload":
                                                                        _vm.controlTypesReload,
                                                                      "enable-select-all":
                                                                        "",
                                                                      "item-text":
                                                                        "description",
                                                                      "item-value":
                                                                        "code",
                                                                      label: "",
                                                                      multiple:
                                                                        "",
                                                                      name: "controlTypes",
                                                                      sortable:
                                                                        "",
                                                                      title:
                                                                        "Control Types",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .selectivityItem[
                                                                          "controlTypes"
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.selectivityItem,
                                                                            "controlTypes",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "selectivityItem['controlTypes']",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              collapsed: "",
                              expandable: "",
                              title: "Controls Results",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.controlTableHeaders,
                                items: _vm.formDataF["consignmentControls"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.id",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showControlLink(
                                                  item["id"]
                                                ),
                                                tag: "a",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { left: "" } },
                                                [_vm._v("mdi-folder-open")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item["controlType"]
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          {
                            attrs: {
                              collapsed: "",
                              expandable: "",
                              title: "Events",
                            },
                          },
                          [
                            _c("v-data-table", {
                              staticClass: "pa-0 ma-0",
                              attrs: {
                                headers: _vm.eventTableHeaders,
                                items: _vm.formDataF["events"],
                                "items-per-page": -1,
                                "hide-default-footer": "",
                                "multi-sort": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "item.eventId",
                                  fn: function (ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-display-div" },
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.showEventLink(
                                                  item["eventId"]
                                                ),
                                                tag: "a",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticStyle: {
                                                    "text-decoration": "unset",
                                                  },
                                                  attrs: { color: "primary" },
                                                },
                                                [_vm._v("mdi-file-link")]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-display-text form-display-link",
                                                  staticStyle: {
                                                    color: "unset",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item["eventId"]) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.formDataF["customsResponse"] &&
                _vm.formDataF["customsResponse"].length > 0
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "asyent-card",
                              {
                                attrs: {
                                  collapsed: "",
                                  expandable: "",
                                  title: "Customs Responses",
                                },
                              },
                              [
                                _c("v-data-table", {
                                  staticClass: "pa-0 ma-0",
                                  attrs: {
                                    headers: _vm.customsResponseHeaders,
                                    items: _vm.formDataF["customsResponse"],
                                    "items-per-page": -1,
                                    "hide-default-footer": "",
                                    "multi-sort": "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.rop !== "history" ? _c("asyent-toc") : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.formValid,
          callback: function ($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }