var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _vm.currentFile && _vm.messages.length === 0
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-progress-linear",
                {
                  attrs: {
                    color: "primary",
                    "background-color": "background",
                    height: "25",
                    reactive: "",
                  },
                  model: {
                    value: _vm.progress,
                    callback: function ($$v) {
                      _vm.progress = $$v
                    },
                    expression: "progress",
                  },
                },
                [_c("strong", [_vm._v(_vm._s(_vm.progress) + " %")])]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.viewMode
        ? _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c("v-file-input", {
                attrs: {
                  "show-size": "",
                  dense: _vm.appConfig.forms.dense,
                  flat: _vm.appConfig.forms.flat,
                  solo: _vm.appConfig.forms.solo,
                  "solo-inverted": _vm.appConfig.forms.soloInverted,
                  filled: _vm.appConfig.forms.filled,
                  outlined: _vm.appConfig.forms.outlined,
                  "error-messages": _vm.messages,
                  label: "File input",
                },
                on: { change: _vm.selectFile },
                model: {
                  value: _vm.filename,
                  callback: function ($$v) {
                    _vm.filename = $$v
                  },
                  expression: "filename",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.viewMode
        ? _c(
            "v-col",
            { staticClass: "pl-2", attrs: { cols: "12", md: "6" } },
            [
              !_vm.viewMode && !_vm.updateMode
                ? _c("asyent-button", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      "button-icon": "mdi-cloud-upload",
                      "button-tooltip": _vm.ae$lng("Upload"),
                      icon: _vm.$vuetify.breakpoint.smAndDown,
                      text: !_vm.$vuetify.breakpoint.smAndDown,
                    },
                    on: { click: _vm.upload },
                  })
                : _vm._e(),
              _vm.updateMode
                ? _c("asyent-button", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      "button-icon": "mdi-file-document-edit-outline",
                      "button-tooltip": _vm.ae$lng("Update"),
                      icon: _vm.$vuetify.breakpoint.smAndDown,
                      text: !_vm.$vuetify.breakpoint.smAndDown,
                    },
                    on: { click: _vm.UpdateItem },
                  })
                : _vm._e(),
              _vm.updateMode
                ? _c("asyent-button", {
                    staticClass: "ma-0 pa-0",
                    attrs: {
                      "button-icon": "mdi-close-box-outline",
                      "button-tooltip": _vm.ae$lng("Cancel"),
                      icon: _vm.$vuetify.breakpoint.smAndDown,
                      text: !_vm.$vuetify.breakpoint.smAndDown,
                    },
                    on: { click: _vm.cancelUpdate },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.fileInfos.length > 0 && !_vm.viewMode
        ? _c("v-col", { attrs: { cols: "12" } }, [_c("v-divider")], 1)
        : _vm._e(),
      _vm.fileInfos.length > 0
        ? _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "mx-auto", attrs: { flat: "" } },
                [
                  _c("v-simple-table", {
                    attrs: { "fixed-header": "", height: "300px" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function () {
                            return [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(" #"),
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(" Code"),
                                  ]),
                                  _vm.viewMode
                                    ? _c("th", { staticClass: "text-left" }, [
                                        _vm._v(" Name"),
                                      ])
                                    : _vm._e(),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(" File Name"),
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(" File Size"),
                                  ]),
                                  _c("th", { staticClass: "text-left" }, [
                                    _vm._v(" Actions"),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                _vm._l(_vm.fileInfos, function (item, index) {
                                  return _c("tr", { key: item.id }, [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c(
                                      "td",
                                      [
                                        _vm.viewMode
                                          ? _c("asyent-form-display", {
                                              attrs: {
                                                value: [
                                                  _vm.fileInfos[index]["info"][
                                                    "code"
                                                  ],
                                                  _vm.fileInfos[index]["info"][
                                                    "name"
                                                  ],
                                                ],
                                                label: _vm.label,
                                              },
                                            })
                                          : _c("asyent-form-catalog", {
                                              attrs: {
                                                sortable: "",
                                                "view-mode": _vm.viewMode,
                                                "form-errors": {},
                                                "form-rules": {},
                                                "is-disabled-func":
                                                  function () {
                                                    return _vm.viewMode
                                                  },
                                                "service-name": "catalog",
                                                catalog: "CatalogAttachments",
                                                "show-item-code": "",
                                                "no-label": "",
                                                "item-value": "code",
                                                "item-text": "name",
                                                name: "medias.code",
                                                "return-object": "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  _vm.viewMode
                                                    ? {
                                                        key: "selection",
                                                        fn: function (ref) {
                                                          var si = ref.item
                                                          return [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  si["code"]
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        },
                                                      }
                                                    : null,
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value:
                                                  _vm.fileInfos[index]["info"],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.fileInfos[index],
                                                    "info",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fileInfos[index]['info']",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                    _vm.viewMode
                                      ? _c("td", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.fileInfos[index]["info"][
                                                "name"
                                              ]
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c("td", [_vm._v(_vm._s(item.name))]),
                                    _c("td", [_vm._v(_vm._s(item.size))]),
                                    _c(
                                      "td",
                                      [
                                        _c("asyent-button", {
                                          staticClass: "ma-0 pa-0",
                                          attrs: {
                                            "button-icon":
                                              "mdi-file-eye-outline",
                                            "button-tooltip":
                                              _vm.ae$lng("View"),
                                            icon: _vm.$vuetify.breakpoint
                                              .smAndDown,
                                            text: !_vm.$vuetify.breakpoint
                                              .smAndDown,
                                          },
                                          on: {
                                            confirmed: function ($event) {
                                              return _vm.downloadItem(item)
                                            },
                                          },
                                        }),
                                        !_vm.viewMode
                                          ? _c("asyent-button", {
                                              staticClass: "ma-0 pa-0",
                                              attrs: {
                                                "button-icon":
                                                  "mdi-file-document-edit-outline",
                                                "button-tooltip":
                                                  _vm.ae$lng("Update"),
                                                icon: _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                                text: !_vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.initiateUpdate(
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        !_vm.viewMode
                                          ? _c("asyent-button", {
                                              staticClass: "ma-0 pa-0",
                                              attrs: {
                                                "button-icon":
                                                  "mdi-delete-outline",
                                                "button-tooltip":
                                                  _vm.ae$lng("Delete"),
                                                icon: _vm.$vuetify.breakpoint
                                                  .smAndDown,
                                                text: !_vm.$vuetify.breakpoint
                                                  .smAndDown,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.remove(item, index)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ])
                                }),
                                0
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2966302987
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }